import { ReactNode } from 'react';
// @ts-ignore No type declaration for lib
import { ModalBox } from 'components/ModalBox';
import Content from './components/Content';
import { getCardBgColor } from 'components/Cards/helpers/getCardBgColor';
import { useGlobalContext } from 'components/Context/hooks/useGlobalContext';
import { NextLink } from '../NextLink';

export interface ExtendedAsset {
  desktop: string;
  mobile: string;
  background: string;
  hero: string;
  tablet: string;
  width?: number | null;
  height?: number | null;
  mimeType?: string | null;
}

export interface IAuthorableContentCardProps {
  title?: string;
  subtitle?: string | null;
  copy: string;
  link?: ILinkProps | null;
  videoId?: string | null;
  videoButtonText?: string;
  image?: ExtendedAsset | null;
  height?: string;
  playColorScheme?: string;
  isAuthorableGrid?: boolean;
  position?: number;
  trackingEvent?: {
    clickAuthorableCard: (title: string, position: number | undefined) => void;
    clickOccasionCard: (data: any) => void;
  };
  isOdd?: boolean;
  description?: string;
  thumbnailUrls?: string[];
  embedUrl?: string;
  uploadDate?: string;
  duration?: string;
  interactionCount?: number;
}

interface ILinkProps {
  url?: string | null;
  text?: string;
  openInNewTab?: boolean;
}

export const AuthorableContentCard = ({
  title = '',
  subtitle = '',
  copy = '',
  videoId = '',
  videoButtonText = '',
  link,
  image,
  height = '500px',
  playColorScheme = 'Black',
  isAuthorableGrid = false,
  position = 1,
  trackingEvent,
  isOdd = false,
  description,
  thumbnailUrls,
  embedUrl,
  uploadDate,
  duration,
  interactionCount,
}: IAuthorableContentCardProps): ReactNode => {
  const { theme } = useGlobalContext();

  const cardColor = getCardBgColor({ theme, isOdd });
  const id = String(Math.floor(Math.random() * (10000 - 0)));
  const componentName = `${id}-authorable-card`;
  const onCardClick = () => {
    if (trackingEvent) {
      if (link?.url?.includes('products')) {
        trackingEvent?.clickAuthorableCard(title, position);
      } else trackingEvent?.clickOccasionCard(title);
    }
  };

  const content = (
    <Content
      height={height}
      componentName={componentName}
      image={image}
      cardColor={cardColor}
      showVideo={!!videoId}
      subtitle={subtitle}
      title={title}
      copy={copy}
      isAuthorableGrid={isAuthorableGrid}
      videoButtonText={videoButtonText}
      linkText={link?.text}
    />
  );

  if (videoId) {
    return (
      <div className="modalBoxWrapper group">
        <ModalBox
          videoID={videoId}
          colorScheme={playColorScheme}
          playButton={content}
          title={title}
          description={description || ''}
          thumbnailUrls={thumbnailUrls ?? []}
          embedUrl={embedUrl || ''}
          uploadDate={uploadDate || ''}
          duration={duration || ''}
          interactionCount={interactionCount || 0}
        />
      </div>
    );
  }

  return (
    <NextLink
      className="block text-darkgray w-full"
      href={link?.url ?? '/'}
      target={link?.openInNewTab ? '_blank' : undefined}
      data-chromatic="ignore"
      onClick={onCardClick}
    >
      {content}
    </NextLink>
  );
};
