import ContentTopCurve from './ContentTopCurve';
import { Text as Paragraph, Title } from '../../Typography';
import { SeeMoreCardArrow } from '@icons/SeeMoreCardArrow';
import { BIG_CARD_DIMENSION } from './Content';

interface ICardContentProps {
  cardColor: string;
  showVideo: boolean;
  subtitle?: string | null;
  title?: string;
  copy?: string;
  contentWidth: number;
  isAuthorableGrid: boolean;
  componentName: string;
  videoButtonText?: string;
  linkText?: string;
}

const CardContent = ({
  showVideo,
  cardColor,
  subtitle,
  title,
  copy,
  contentWidth,
  isAuthorableGrid,
  componentName,
  videoButtonText,
  linkText,
}: ICardContentProps) => {
  const titleType = contentWidth < 370 && isAuthorableGrid ? 'xxxs' : 'sm';

  return (
    <div className="relative card-content">
      <ContentTopCurve showVideo={showVideo} cardColor={cardColor} />
      <div
        className={
          contentWidth > BIG_CARD_DIMENSION
            ? 'relative -top-8 lg:-top-12 feature-card-content-big'
            : 'relative -top-5 feature-card-content'
        }
      >
        {subtitle && (
          <Paragraph tag="p" type="lead" className="pb-2" data-test="subtitle">
            {subtitle}
          </Paragraph>
        )}
        {title && (
          <Title data-test="title" tag="div" type={titleType} className="pb-4">
            {title}
          </Title>
        )}
        {copy && (
          <Title tag="p" type="xxs" data-test="copy-text" className="pb-4 font-medium tracking-xs">
            {copy}
          </Title>
        )}
        <div
          data-test="hidden-until-hover-content-card-link"
          className="show-over-hover mmmd:focus:max-h-[100px] mmmd:focus:opacity-100 mmmd:opacity-0 mmmd:max-h-0 pb-0 mmmd:transition mmmd:ease-in-out duration-[800ms] mmmd:group-hover:opacity-100 mmmd:group-hover:max-h-[100px]"
        >
          <Paragraph
            data-chromatic="ignore"
            data-test={`${componentName}-cta-text`}
            tag="div"
            type="lead"
            className="pt-10 inline text-base font-medium tracking-widest"
          >
            <span className="uppercase">{showVideo ? videoButtonText : linkText}</span>
            <span aria-hidden="true" className="ml-18 inline align-middle">
              <SeeMoreCardArrow
                className="inline align-top"
                data-chromatic="ignore"
                data-test={`${componentName}-right-arrow`}
              />
            </span>
          </Paragraph>
        </div>
      </div>
    </div>
  );
};

export default CardContent;
