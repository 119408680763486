import { PlayVideo } from '@icons/PlayVideo';

interface IContentTopCurveProps {
  cardColor: string;
  showVideo: boolean;
}

const ContentTopCurve = ({ showVideo, cardColor }: IContentTopCurveProps) => {
  return (
    <div className="absolute bottom-[calc(100%+18px)] mmmd:left-[-20px] mmmd:w-[calc(100%+40px)] leading-[0] mmmd:bottom-[calc(100%+16px)] -left-10 w-[calc(100%+80px)]">
      <svg
        id="contentTopCurve"
        data-test="svg-other-variation-exists"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 624 28"
      >
        <path
          fill={cardColor}
          d="M16 203C7.16344 203 0 195.837 0 187V21.351C0 21.351 143.433 -1.4836e-05 313.3 0C483.167 1.48361e-05 624 21.3511 624 21.3511V187C624 195.837 616.836 203 608 203H16Z"
        />
      </svg>
      {showVideo && (
        <div
          className="absolute left-[calc(50%-15px)] top-0 w-[30px] h-[30px] z-[1] p-[5px] rounded-full -translate-y-1/2 "
          style={{ backgroundColor: cardColor }}
          data-test="video-play-button"
        >
          <PlayVideo data-test="card-video" />
        </div>
      )}
    </div>
  );
};

export default ContentTopCurve;
