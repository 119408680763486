import NextImage from '../../NextImage';
import clsx from 'clsx';
import CardContent from './CardContent';
import { useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'debounce';
import { ExtendedAsset } from '../index';

export const BIG_CARD_DIMENSION = 900;

interface IContentProps {
  height?: string;
  componentName: string;
  image?: ExtendedAsset | null;
  cardColor: string;
  showVideo: boolean;
  subtitle?: string | null;
  title?: string;
  copy?: string;
  isAuthorableGrid: boolean;
  videoButtonText?: string;
  linkText?: string;
}

const Content = ({
  height,
  componentName,
  image,
  cardColor,
  showVideo,
  subtitle,
  title,
  copy,
  isAuthorableGrid,
  videoButtonText,
  linkText,
}: IContentProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState<number>(0);
  const [contentWidth, setContentWidth] = useState<number>(150);

  const debouncedCalculateContentWidthHeight = useCallback(
    () =>
      debounce(() => {
        if (contentRef.current) {
          setContentHeight(contentRef?.current?.clientHeight);
          setContentWidth(contentRef?.current?.clientWidth);
        }
      }, 100),
    [],
  );

  useEffect(() => {
    debouncedCalculateContentWidthHeight();
  }, [contentRef, debouncedCalculateContentWidthHeight]);

  useEffect(() => {
    window.addEventListener('resize', debouncedCalculateContentWidthHeight);

    return () => window.removeEventListener('resize', debouncedCalculateContentWidthHeight);
  }, [debouncedCalculateContentWidthHeight]);

  return (
    <div
      className="contentCardWrapper w-full cursor-pointer text-center group"
      data-chromatic="ignore"
      data-test={`${componentName}-wrapper`}
      style={{ height }}
      tabIndex={0}
    >
      <div
        data-chromatic="ignore"
        className="relative flex flex-col items-stretch justify-items-stretch h-full"
        style={contentHeight ? { paddingBottom: contentHeight } : {}}
        data-test={`${componentName}-container`}
      >
        {image?.desktop && (
          <NextImage
            src={image.desktop}
            alt="Authorable card image"
            preferredWidth={750}
            width={image.width ?? 750}
            height={image.width ?? 750}
            className="w-full h-full object-cover top-0 left-0 grow-[2] overflow-hidden min-h-[300px] rounded-t relative md:min-h-[226px]"
            mimeType={String(image?.mimeType)}
            data-chromatic="ignore"
            data-test={`${componentName}-image`}
          />
        )}
        <div
          data-chromatic="ignore"
          data-test={`${componentName}-content`}
          ref={contentRef}
          className={clsx(
            'contentComponent',
            contentWidth > BIG_CARD_DIMENSION && 'pb-0',
            'pt-5 mmmd:px-5 px-10 mmmd:pb-[30px] pb-2.5 relative w-full rounded-b card-content',
          )}
          style={{ backgroundColor: cardColor }}
        >
          <CardContent
            cardColor={cardColor}
            showVideo={showVideo}
            subtitle={subtitle}
            title={title}
            contentWidth={contentWidth}
            isAuthorableGrid={isAuthorableGrid}
            copy={copy}
            componentName={componentName}
            videoButtonText={videoButtonText}
            linkText={linkText}
          />
        </div>
      </div>
    </div>
  );
};

export default Content;
